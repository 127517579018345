// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { frontmatter as fm0, toc as t0 } from '/opt/buildhome/repo/.dumi/pages/index/common/CustomizationProvider.tsx?type=frontmatter';
import { frontmatter as fm1, toc as t1 } from '/opt/buildhome/repo/.dumi/pages/index/common/CustomizationSender.tsx?type=frontmatter';
import { frontmatter as fm2, toc as t2 } from '/opt/buildhome/repo/.dumi/pages/index/common/Introduction.tsx?type=frontmatter';
import { frontmatter as fm3, toc as t3 } from '/opt/buildhome/repo/.dumi/pages/index/common/Container.tsx?type=frontmatter';
import { frontmatter as fm4, toc as t4 } from '/opt/buildhome/repo/.dumi/pages/theme-editor-cn/index.ts?type=frontmatter';
import { frontmatter as fm5, toc as t5 } from '/opt/buildhome/repo/.dumi/pages/theme-editor/index.tsx?type=frontmatter';
import { frontmatter as fm6, toc as t6 } from '/opt/buildhome/repo/.dumi/pages/index-cn/index.ts?type=frontmatter';
import { frontmatter as fm7, toc as t7 } from '/opt/buildhome/repo/.dumi/pages/index/index.tsx?type=frontmatter';
import { frontmatter as fm8, toc as t8 } from '/opt/buildhome/repo/docs/spec/provide-intention-expectation.en-US.md?type=frontmatter';
import { demoIndex as dmi8 } from '/opt/buildhome/repo/docs/spec/provide-intention-expectation.en-US.md?type=demo-index';
import { frontmatter as fm9, toc as t9 } from '/opt/buildhome/repo/docs/spec/provide-intention-expectation.zh-CN.md?type=frontmatter';
import { demoIndex as dmi9 } from '/opt/buildhome/repo/docs/spec/provide-intention-expectation.zh-CN.md?type=demo-index';
import { frontmatter as fm10, toc as t10 } from '/opt/buildhome/repo/docs/spec/feedback-result-application.en-US.md?type=frontmatter';
import { demoIndex as dmi10 } from '/opt/buildhome/repo/docs/spec/feedback-result-application.en-US.md?type=demo-index';
import { frontmatter as fm11, toc as t11 } from '/opt/buildhome/repo/docs/spec/feedback-result-application.zh-CN.md?type=frontmatter';
import { demoIndex as dmi11 } from '/opt/buildhome/repo/docs/spec/feedback-result-application.zh-CN.md?type=demo-index';
import { frontmatter as fm12, toc as t12 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=frontmatter';
import { demoIndex as dmi12 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=demo-index';
import { frontmatter as fm13, toc as t13 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=frontmatter';
import { demoIndex as dmi13 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=demo-index';
import { frontmatter as fm14, toc as t14 } from '/opt/buildhome/repo/docs/spec/confirm-generation-process.en-US.md?type=frontmatter';
import { demoIndex as dmi14 } from '/opt/buildhome/repo/docs/spec/confirm-generation-process.en-US.md?type=demo-index';
import { frontmatter as fm15, toc as t15 } from '/opt/buildhome/repo/docs/spec/confirm-generation-process.zh-CN.md?type=frontmatter';
import { demoIndex as dmi15 } from '/opt/buildhome/repo/docs/spec/confirm-generation-process.zh-CN.md?type=demo-index';
import { frontmatter as fm16, toc as t16 } from '/opt/buildhome/repo/docs/spec/guide-intention-expression.en-US.md?type=frontmatter';
import { demoIndex as dmi16 } from '/opt/buildhome/repo/docs/spec/guide-intention-expression.en-US.md?type=demo-index';
import { frontmatter as fm17, toc as t17 } from '/opt/buildhome/repo/docs/spec/guide-intention-expression.zh-CN.md?type=frontmatter';
import { demoIndex as dmi17 } from '/opt/buildhome/repo/docs/spec/guide-intention-expression.zh-CN.md?type=demo-index';
import { frontmatter as fm18, toc as t18 } from '/opt/buildhome/repo/docs/spec/feedback-result-display.en-US.md?type=frontmatter';
import { demoIndex as dmi18 } from '/opt/buildhome/repo/docs/spec/feedback-result-display.en-US.md?type=demo-index';
import { frontmatter as fm19, toc as t19 } from '/opt/buildhome/repo/docs/spec/feedback-result-display.zh-CN.md?type=frontmatter';
import { demoIndex as dmi19 } from '/opt/buildhome/repo/docs/spec/feedback-result-display.zh-CN.md?type=demo-index';
import { frontmatter as fm20, toc as t20 } from '/opt/buildhome/repo/docs/spec/wake-up-welcome-message.en-US.md?type=frontmatter';
import { demoIndex as dmi20 } from '/opt/buildhome/repo/docs/spec/wake-up-welcome-message.en-US.md?type=demo-index';
import { frontmatter as fm21, toc as t21 } from '/opt/buildhome/repo/docs/spec/wake-up-welcome-message.zh-CN.md?type=frontmatter';
import { demoIndex as dmi21 } from '/opt/buildhome/repo/docs/spec/wake-up-welcome-message.zh-CN.md?type=demo-index';
import { frontmatter as fm22, toc as t22 } from '/opt/buildhome/repo/docs/spec/define-intention-type.en-US.md?type=frontmatter';
import { demoIndex as dmi22 } from '/opt/buildhome/repo/docs/spec/define-intention-type.en-US.md?type=demo-index';
import { frontmatter as fm23, toc as t23 } from '/opt/buildhome/repo/docs/spec/define-intention-type.zh-CN.md?type=frontmatter';
import { demoIndex as dmi23 } from '/opt/buildhome/repo/docs/spec/define-intention-type.zh-CN.md?type=demo-index';
import { frontmatter as fm24, toc as t24 } from '/opt/buildhome/repo/docs/spec/expression-user-input.en-US.md?type=frontmatter';
import { demoIndex as dmi24 } from '/opt/buildhome/repo/docs/spec/expression-user-input.en-US.md?type=demo-index';
import { frontmatter as fm25, toc as t25 } from '/opt/buildhome/repo/docs/spec/expression-user-input.zh-CN.md?type=frontmatter';
import { demoIndex as dmi25 } from '/opt/buildhome/repo/docs/spec/expression-user-input.zh-CN.md?type=demo-index';
import { frontmatter as fm26, toc as t26 } from '/opt/buildhome/repo/docs/react/dangerously-api-key.en-US.md?type=frontmatter';
import { demoIndex as dmi26 } from '/opt/buildhome/repo/docs/react/dangerously-api-key.en-US.md?type=demo-index';
import { frontmatter as fm27, toc as t27 } from '/opt/buildhome/repo/docs/react/dangerously-api-key.zh-CN.md?type=frontmatter';
import { demoIndex as dmi27 } from '/opt/buildhome/repo/docs/react/dangerously-api-key.zh-CN.md?type=demo-index';
import { frontmatter as fm28, toc as t28 } from '/opt/buildhome/repo/docs/spec/authentic-consistent.en-US.md?type=frontmatter';
import { demoIndex as dmi28 } from '/opt/buildhome/repo/docs/spec/authentic-consistent.en-US.md?type=demo-index';
import { frontmatter as fm29, toc as t29 } from '/opt/buildhome/repo/docs/spec/authentic-consistent.zh-CN.md?type=frontmatter';
import { demoIndex as dmi29 } from '/opt/buildhome/repo/docs/spec/authentic-consistent.zh-CN.md?type=demo-index';
import { frontmatter as fm30, toc as t30 } from '/opt/buildhome/repo/docs/spec/expression-user-send.en-US.md?type=frontmatter';
import { demoIndex as dmi30 } from '/opt/buildhome/repo/docs/spec/expression-user-send.en-US.md?type=demo-index';
import { frontmatter as fm31, toc as t31 } from '/opt/buildhome/repo/docs/spec/expression-user-send.zh-CN.md?type=frontmatter';
import { demoIndex as dmi31 } from '/opt/buildhome/repo/docs/spec/expression-user-send.zh-CN.md?type=demo-index';
import { frontmatter as fm32, toc as t32 } from '/opt/buildhome/repo/docs/spec/conversation-design.en-US.md?type=frontmatter';
import { demoIndex as dmi32 } from '/opt/buildhome/repo/docs/spec/conversation-design.en-US.md?type=demo-index';
import { frontmatter as fm33, toc as t33 } from '/opt/buildhome/repo/docs/spec/conversation-design.zh-CN.md?type=frontmatter';
import { demoIndex as dmi33 } from '/opt/buildhome/repo/docs/spec/conversation-design.zh-CN.md?type=demo-index';
import { frontmatter as fm34, toc as t34 } from '/opt/buildhome/repo/docs/spec/intention-introduce.en-US.md?type=frontmatter';
import { demoIndex as dmi34 } from '/opt/buildhome/repo/docs/spec/intention-introduce.en-US.md?type=demo-index';
import { frontmatter as fm35, toc as t35 } from '/opt/buildhome/repo/docs/spec/intention-introduce.zh-CN.md?type=frontmatter';
import { demoIndex as dmi35 } from '/opt/buildhome/repo/docs/spec/intention-introduce.zh-CN.md?type=demo-index';
import { frontmatter as fm36, toc as t36 } from '/opt/buildhome/repo/docs/playground/independent.en-US.md?type=frontmatter';
import { demoIndex as dmi36 } from '/opt/buildhome/repo/docs/playground/independent.en-US.md?type=demo-index';
import { frontmatter as fm37, toc as t37 } from '/opt/buildhome/repo/docs/playground/independent.zh-CN.md?type=frontmatter';
import { demoIndex as dmi37 } from '/opt/buildhome/repo/docs/playground/independent.zh-CN.md?type=demo-index';
import { frontmatter as fm38, toc as t38 } from '/opt/buildhome/repo/docs/react/model-use-openai.en-US.md?type=frontmatter';
import { demoIndex as dmi38 } from '/opt/buildhome/repo/docs/react/model-use-openai.en-US.md?type=demo-index';
import { frontmatter as fm39, toc as t39 } from '/opt/buildhome/repo/docs/react/model-use-openai.zh-CN.md?type=frontmatter';
import { demoIndex as dmi39 } from '/opt/buildhome/repo/docs/react/model-use-openai.zh-CN.md?type=demo-index';
import { frontmatter as fm40, toc as t40 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=frontmatter';
import { demoIndex as dmi40 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=demo-index';
import { frontmatter as fm41, toc as t41 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=frontmatter';
import { demoIndex as dmi41 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=demo-index';
import { frontmatter as fm42, toc as t42 } from '/opt/buildhome/repo/docs/react/model-use-other.en-US.md?type=frontmatter';
import { demoIndex as dmi42 } from '/opt/buildhome/repo/docs/react/model-use-other.en-US.md?type=demo-index';
import { frontmatter as fm43, toc as t43 } from '/opt/buildhome/repo/docs/react/model-use-other.zh-CN.md?type=frontmatter';
import { demoIndex as dmi43 } from '/opt/buildhome/repo/docs/react/model-use-other.zh-CN.md?type=demo-index';
import { frontmatter as fm44, toc as t44 } from '/opt/buildhome/repo/docs/spec/hybrid-ui-design.en-US.md?type=frontmatter';
import { demoIndex as dmi44 } from '/opt/buildhome/repo/docs/spec/hybrid-ui-design.en-US.md?type=demo-index';
import { frontmatter as fm45, toc as t45 } from '/opt/buildhome/repo/docs/spec/hybrid-ui-design.zh-CN.md?type=frontmatter';
import { demoIndex as dmi45 } from '/opt/buildhome/repo/docs/spec/hybrid-ui-design.zh-CN.md?type=demo-index';
import { frontmatter as fm46, toc as t46 } from '/opt/buildhome/repo/docs/react/model-use-qwen.en-US.md?type=frontmatter';
import { demoIndex as dmi46 } from '/opt/buildhome/repo/docs/react/model-use-qwen.en-US.md?type=demo-index';
import { frontmatter as fm47, toc as t47 } from '/opt/buildhome/repo/docs/react/model-use-qwen.zh-CN.md?type=frontmatter';
import { demoIndex as dmi47 } from '/opt/buildhome/repo/docs/react/model-use-qwen.zh-CN.md?type=demo-index';
import { frontmatter as fm48, toc as t48 } from '/opt/buildhome/repo/docs/spec/wake-up-ai-icon.en-US.md?type=frontmatter';
import { demoIndex as dmi48 } from '/opt/buildhome/repo/docs/spec/wake-up-ai-icon.en-US.md?type=demo-index';
import { frontmatter as fm49, toc as t49 } from '/opt/buildhome/repo/docs/spec/wake-up-ai-icon.zh-CN.md?type=frontmatter';
import { demoIndex as dmi49 } from '/opt/buildhome/repo/docs/spec/wake-up-ai-icon.zh-CN.md?type=demo-index';
import { frontmatter as fm50, toc as t50 } from '/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=frontmatter';
import { demoIndex as dmi50 } from '/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=demo-index';
import { frontmatter as fm51, toc as t51 } from '/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=frontmatter';
import { demoIndex as dmi51 } from '/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=demo-index';
import { frontmatter as fm52, toc as t52 } from '/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=frontmatter';
import { demoIndex as dmi52 } from '/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=demo-index';
import { frontmatter as fm53, toc as t53 } from '/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=frontmatter';
import { demoIndex as dmi53 } from '/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=demo-index';
import { frontmatter as fm54, toc as t54 } from '/opt/buildhome/repo/docs/spec/smooth-natural.en-US.md?type=frontmatter';
import { demoIndex as dmi54 } from '/opt/buildhome/repo/docs/spec/smooth-natural.en-US.md?type=demo-index';
import { frontmatter as fm55, toc as t55 } from '/opt/buildhome/repo/docs/spec/smooth-natural.zh-CN.md?type=frontmatter';
import { demoIndex as dmi55 } from '/opt/buildhome/repo/docs/spec/smooth-natural.zh-CN.md?type=demo-index';
import { frontmatter as fm56, toc as t56 } from '/opt/buildhome/repo/docs/react/contributing.en-US.md?type=frontmatter';
import { demoIndex as dmi56 } from '/opt/buildhome/repo/docs/react/contributing.en-US.md?type=demo-index';
import { frontmatter as fm57, toc as t57 } from '/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=frontmatter';
import { demoIndex as dmi57 } from '/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=demo-index';
import { frontmatter as fm58, toc as t58 } from '/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=frontmatter';
import { demoIndex as dmi58 } from '/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=demo-index';
import { frontmatter as fm59, toc as t59 } from '/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=frontmatter';
import { demoIndex as dmi59 } from '/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=demo-index';
import { frontmatter as fm60, toc as t60 } from '/opt/buildhome/repo/docs/spec/human-touch.en-US.md?type=frontmatter';
import { demoIndex as dmi60 } from '/opt/buildhome/repo/docs/spec/human-touch.en-US.md?type=demo-index';
import { frontmatter as fm61, toc as t61 } from '/opt/buildhome/repo/docs/spec/human-touch.zh-CN.md?type=frontmatter';
import { demoIndex as dmi61 } from '/opt/buildhome/repo/docs/spec/human-touch.zh-CN.md?type=demo-index';
import { frontmatter as fm62, toc as t62 } from '/opt/buildhome/repo/docs/spec/role-design.en-US.md?type=frontmatter';
import { demoIndex as dmi62 } from '/opt/buildhome/repo/docs/spec/role-design.en-US.md?type=demo-index';
import { frontmatter as fm63, toc as t63 } from '/opt/buildhome/repo/docs/spec/role-design.zh-CN.md?type=frontmatter';
import { demoIndex as dmi63 } from '/opt/buildhome/repo/docs/spec/role-design.zh-CN.md?type=demo-index';
import { frontmatter as fm64, toc as t64 } from '/opt/buildhome/repo/docs/react/introduce.en-US.md?type=frontmatter';
import { demoIndex as dmi64 } from '/opt/buildhome/repo/docs/react/introduce.en-US.md?type=demo-index';
import { frontmatter as fm65, toc as t65 } from '/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=frontmatter';
import { demoIndex as dmi65 } from '/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=demo-index';
import { frontmatter as fm66, toc as t66 } from '/opt/buildhome/repo/docs/spec/follow-up.en-US.md?type=frontmatter';
import { demoIndex as dmi66 } from '/opt/buildhome/repo/docs/spec/follow-up.en-US.md?type=demo-index';
import { frontmatter as fm67, toc as t67 } from '/opt/buildhome/repo/docs/spec/follow-up.zh-CN.md?type=frontmatter';
import { demoIndex as dmi67 } from '/opt/buildhome/repo/docs/spec/follow-up.zh-CN.md?type=demo-index';
import { frontmatter as fm68, toc as t68 } from '/opt/buildhome/repo/docs/spec/introduce.en-US.md?type=frontmatter';
import { demoIndex as dmi68 } from '/opt/buildhome/repo/docs/spec/introduce.en-US.md?type=demo-index';
import { frontmatter as fm69, toc as t69 } from '/opt/buildhome/repo/docs/spec/introduce.zh-CN.md?type=frontmatter';
import { demoIndex as dmi69 } from '/opt/buildhome/repo/docs/spec/introduce.zh-CN.md?type=demo-index';
import { frontmatter as fm70, toc as t70 } from '/opt/buildhome/repo/docs/spec/confirm.en-US.md?type=frontmatter';
import { demoIndex as dmi70 } from '/opt/buildhome/repo/docs/spec/confirm.en-US.md?type=demo-index';
import { frontmatter as fm71, toc as t71 } from '/opt/buildhome/repo/docs/spec/confirm.zh-CN.md?type=frontmatter';
import { demoIndex as dmi71 } from '/opt/buildhome/repo/docs/spec/confirm.zh-CN.md?type=demo-index';
import { frontmatter as fm72, toc as t72 } from '/opt/buildhome/repo/docs/spec/error.en-US.md?type=frontmatter';
import { demoIndex as dmi72 } from '/opt/buildhome/repo/docs/spec/error.en-US.md?type=demo-index';
import { frontmatter as fm73, toc as t73 } from '/opt/buildhome/repo/docs/spec/error.zh-CN.md?type=frontmatter';
import { demoIndex as dmi73 } from '/opt/buildhome/repo/docs/spec/error.zh-CN.md?type=demo-index';
import { frontmatter as fm74, toc as t74 } from '/opt/buildhome/repo/docs/spec/start.en-US.md?type=frontmatter';
import { demoIndex as dmi74 } from '/opt/buildhome/repo/docs/spec/start.en-US.md?type=demo-index';
import { frontmatter as fm75, toc as t75 } from '/opt/buildhome/repo/docs/spec/start.zh-CN.md?type=frontmatter';
import { demoIndex as dmi75 } from '/opt/buildhome/repo/docs/spec/start.zh-CN.md?type=demo-index';
import { frontmatter as fm76, toc as t76 } from '/opt/buildhome/repo/docs/react/faq.en-US.md?type=frontmatter';
import { demoIndex as dmi76 } from '/opt/buildhome/repo/docs/react/faq.en-US.md?type=demo-index';
import { frontmatter as fm77, toc as t77 } from '/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=frontmatter';
import { demoIndex as dmi77 } from '/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=demo-index';
import { frontmatter as fm78, toc as t78 } from '/opt/buildhome/repo/docs/spec/hint.en-US.md?type=frontmatter';
import { demoIndex as dmi78 } from '/opt/buildhome/repo/docs/spec/hint.en-US.md?type=demo-index';
import { frontmatter as fm79, toc as t79 } from '/opt/buildhome/repo/docs/spec/hint.zh-CN.md?type=frontmatter';
import { demoIndex as dmi79 } from '/opt/buildhome/repo/docs/spec/hint.zh-CN.md?type=demo-index';
import { frontmatter as fm80, toc as t80 } from '/opt/buildhome/repo/docs/spec/end.en-US.md?type=frontmatter';
import { demoIndex as dmi80 } from '/opt/buildhome/repo/docs/spec/end.en-US.md?type=demo-index';
import { frontmatter as fm81, toc as t81 } from '/opt/buildhome/repo/docs/spec/end.zh-CN.md?type=frontmatter';
import { demoIndex as dmi81 } from '/opt/buildhome/repo/docs/spec/end.zh-CN.md?type=demo-index';
import { frontmatter as fm82, toc as t82 } from '/opt/buildhome/repo/components/attachments/index.en-US.md?type=frontmatter';
import { demoIndex as dmi82 } from '/opt/buildhome/repo/components/attachments/index.en-US.md?type=demo-index';
import { frontmatter as fm83, toc as t83 } from '/opt/buildhome/repo/components/attachments/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi83 } from '/opt/buildhome/repo/components/attachments/index.zh-CN.md?type=demo-index';
import { frontmatter as fm84, toc as t84 } from '/opt/buildhome/repo/components/bubble/index.en-US.md?type=frontmatter';
import { demoIndex as dmi84 } from '/opt/buildhome/repo/components/bubble/index.en-US.md?type=demo-index';
import { frontmatter as fm85, toc as t85 } from '/opt/buildhome/repo/components/bubble/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi85 } from '/opt/buildhome/repo/components/bubble/index.zh-CN.md?type=demo-index';
import { frontmatter as fm86, toc as t86 } from '/opt/buildhome/repo/components/conversations/index.en-US.md?type=frontmatter';
import { demoIndex as dmi86 } from '/opt/buildhome/repo/components/conversations/index.en-US.md?type=demo-index';
import { frontmatter as fm87, toc as t87 } from '/opt/buildhome/repo/components/conversations/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi87 } from '/opt/buildhome/repo/components/conversations/index.zh-CN.md?type=demo-index';
import { frontmatter as fm88, toc as t88 } from '/opt/buildhome/repo/components/overview/index.en-US.md?type=frontmatter';
import { demoIndex as dmi88 } from '/opt/buildhome/repo/components/overview/index.en-US.md?type=demo-index';
import { frontmatter as fm89, toc as t89 } from '/opt/buildhome/repo/components/overview/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi89 } from '/opt/buildhome/repo/components/overview/index.zh-CN.md?type=demo-index';
import { frontmatter as fm90, toc as t90 } from '/opt/buildhome/repo/components/prompts/index.en-US.md?type=frontmatter';
import { demoIndex as dmi90 } from '/opt/buildhome/repo/components/prompts/index.en-US.md?type=demo-index';
import { frontmatter as fm91, toc as t91 } from '/opt/buildhome/repo/components/prompts/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi91 } from '/opt/buildhome/repo/components/prompts/index.zh-CN.md?type=demo-index';
import { frontmatter as fm92, toc as t92 } from '/opt/buildhome/repo/components/sender/index.en-US.md?type=frontmatter';
import { demoIndex as dmi92 } from '/opt/buildhome/repo/components/sender/index.en-US.md?type=demo-index';
import { frontmatter as fm93, toc as t93 } from '/opt/buildhome/repo/components/sender/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi93 } from '/opt/buildhome/repo/components/sender/index.zh-CN.md?type=demo-index';
import { frontmatter as fm94, toc as t94 } from '/opt/buildhome/repo/components/suggestion/index.en-US.md?type=frontmatter';
import { demoIndex as dmi94 } from '/opt/buildhome/repo/components/suggestion/index.en-US.md?type=demo-index';
import { frontmatter as fm95, toc as t95 } from '/opt/buildhome/repo/components/suggestion/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi95 } from '/opt/buildhome/repo/components/suggestion/index.zh-CN.md?type=demo-index';
import { frontmatter as fm96, toc as t96 } from '/opt/buildhome/repo/components/thought-chain/index.en-US.md?type=frontmatter';
import { demoIndex as dmi96 } from '/opt/buildhome/repo/components/thought-chain/index.en-US.md?type=demo-index';
import { frontmatter as fm97, toc as t97 } from '/opt/buildhome/repo/components/thought-chain/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi97 } from '/opt/buildhome/repo/components/thought-chain/index.zh-CN.md?type=demo-index';
import { frontmatter as fm98, toc as t98 } from '/opt/buildhome/repo/components/useXAgent/index.en-US.md?type=frontmatter';
import { demoIndex as dmi98 } from '/opt/buildhome/repo/components/useXAgent/index.en-US.md?type=demo-index';
import { frontmatter as fm99, toc as t99 } from '/opt/buildhome/repo/components/useXAgent/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi99 } from '/opt/buildhome/repo/components/useXAgent/index.zh-CN.md?type=demo-index';
import { frontmatter as fm100, toc as t100 } from '/opt/buildhome/repo/components/useXChat/index.en-US.md?type=frontmatter';
import { demoIndex as dmi100 } from '/opt/buildhome/repo/components/useXChat/index.en-US.md?type=demo-index';
import { frontmatter as fm101, toc as t101 } from '/opt/buildhome/repo/components/useXChat/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi101 } from '/opt/buildhome/repo/components/useXChat/index.zh-CN.md?type=demo-index';
import { frontmatter as fm102, toc as t102 } from '/opt/buildhome/repo/components/welcome/index.en-US.md?type=frontmatter';
import { demoIndex as dmi102 } from '/opt/buildhome/repo/components/welcome/index.en-US.md?type=demo-index';
import { frontmatter as fm103, toc as t103 } from '/opt/buildhome/repo/components/welcome/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi103 } from '/opt/buildhome/repo/components/welcome/index.zh-CN.md?type=demo-index';
import { frontmatter as fm104, toc as t104 } from '/opt/buildhome/repo/components/x-provider/index.en-US.md?type=frontmatter';
import { demoIndex as dmi104 } from '/opt/buildhome/repo/components/x-provider/index.en-US.md?type=demo-index';
import { frontmatter as fm105, toc as t105 } from '/opt/buildhome/repo/components/x-provider/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi105 } from '/opt/buildhome/repo/components/x-provider/index.zh-CN.md?type=demo-index';
import { frontmatter as fm106, toc as t106 } from '/opt/buildhome/repo/components/x-request/index.en-US.md?type=frontmatter';
import { demoIndex as dmi106 } from '/opt/buildhome/repo/components/x-request/index.en-US.md?type=demo-index';
import { frontmatter as fm107, toc as t107 } from '/opt/buildhome/repo/components/x-request/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi107 } from '/opt/buildhome/repo/components/x-request/index.zh-CN.md?type=demo-index';
import { frontmatter as fm108, toc as t108 } from '/opt/buildhome/repo/components/x-stream/index.en-US.md?type=frontmatter';
import { demoIndex as dmi108 } from '/opt/buildhome/repo/components/x-stream/index.en-US.md?type=demo-index';
import { frontmatter as fm109, toc as t109 } from '/opt/buildhome/repo/components/x-stream/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi109 } from '/opt/buildhome/repo/components/x-stream/index.zh-CN.md?type=demo-index';
import { frontmatter as fm110, toc as t110 } from '/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=frontmatter';
import { demoIndex as dmi110 } from '/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=demo-index';
import { frontmatter as fm111, toc as t111 } from '/opt/buildhome/repo/CHANGELOG.en-US.md?type=frontmatter';
import { demoIndex as dmi111 } from '/opt/buildhome/repo/CHANGELOG.en-US.md?type=demo-index';

export const filesMeta = {
  'index/common/CustomizationProvider': {
    frontmatter: fm0,
    toc: t0,
  },
  'index/common/CustomizationSender': {
    frontmatter: fm1,
    toc: t1,
  },
  'index/common/Introduction': {
    frontmatter: fm2,
    toc: t2,
  },
  'index/common/Container': {
    frontmatter: fm3,
    toc: t3,
  },
  'theme-editor-cn/index': {
    frontmatter: fm4,
    toc: t4,
  },
  'theme-editor/index': {
    frontmatter: fm5,
    toc: t5,
  },
  'index-cn/index': {
    frontmatter: fm6,
    toc: t6,
  },
  'index/index': {
    frontmatter: fm7,
    toc: t7,
  },
  'docs/spec/provide-intention-expectation.en-US': {
    frontmatter: fm8,
    toc: t8,
    demoIndex: dmi8,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/provide-intention-expectation.en-US.md?type=text'),
  },
  'docs/spec/provide-intention-expectation.zh-CN': {
    frontmatter: fm9,
    toc: t9,
    demoIndex: dmi9,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/provide-intention-expectation.zh-CN.md?type=text'),
  },
  'docs/spec/feedback-result-application.en-US': {
    frontmatter: fm10,
    toc: t10,
    demoIndex: dmi10,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/feedback-result-application.en-US.md?type=text'),
  },
  'docs/spec/feedback-result-application.zh-CN': {
    frontmatter: fm11,
    toc: t11,
    demoIndex: dmi11,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/feedback-result-application.zh-CN.md?type=text'),
  },
  'docs/react/use-with-create-react-app.en-US': {
    frontmatter: fm12,
    toc: t12,
    demoIndex: dmi12,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=text'),
  },
  'docs/react/use-with-create-react-app.zh-CN': {
    frontmatter: fm13,
    toc: t13,
    demoIndex: dmi13,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=text'),
  },
  'docs/spec/confirm-generation-process.en-US': {
    frontmatter: fm14,
    toc: t14,
    demoIndex: dmi14,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/confirm-generation-process.en-US.md?type=text'),
  },
  'docs/spec/confirm-generation-process.zh-CN': {
    frontmatter: fm15,
    toc: t15,
    demoIndex: dmi15,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/confirm-generation-process.zh-CN.md?type=text'),
  },
  'docs/spec/guide-intention-expression.en-US': {
    frontmatter: fm16,
    toc: t16,
    demoIndex: dmi16,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/guide-intention-expression.en-US.md?type=text'),
  },
  'docs/spec/guide-intention-expression.zh-CN': {
    frontmatter: fm17,
    toc: t17,
    demoIndex: dmi17,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/guide-intention-expression.zh-CN.md?type=text'),
  },
  'docs/spec/feedback-result-display.en-US': {
    frontmatter: fm18,
    toc: t18,
    demoIndex: dmi18,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/feedback-result-display.en-US.md?type=text'),
  },
  'docs/spec/feedback-result-display.zh-CN': {
    frontmatter: fm19,
    toc: t19,
    demoIndex: dmi19,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/feedback-result-display.zh-CN.md?type=text'),
  },
  'docs/spec/wake-up-welcome-message.en-US': {
    frontmatter: fm20,
    toc: t20,
    demoIndex: dmi20,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/wake-up-welcome-message.en-US.md?type=text'),
  },
  'docs/spec/wake-up-welcome-message.zh-CN': {
    frontmatter: fm21,
    toc: t21,
    demoIndex: dmi21,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/wake-up-welcome-message.zh-CN.md?type=text'),
  },
  'docs/spec/define-intention-type.en-US': {
    frontmatter: fm22,
    toc: t22,
    demoIndex: dmi22,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/define-intention-type.en-US.md?type=text'),
  },
  'docs/spec/define-intention-type.zh-CN': {
    frontmatter: fm23,
    toc: t23,
    demoIndex: dmi23,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/define-intention-type.zh-CN.md?type=text'),
  },
  'docs/spec/expression-user-input.en-US': {
    frontmatter: fm24,
    toc: t24,
    demoIndex: dmi24,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/expression-user-input.en-US.md?type=text'),
  },
  'docs/spec/expression-user-input.zh-CN': {
    frontmatter: fm25,
    toc: t25,
    demoIndex: dmi25,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/expression-user-input.zh-CN.md?type=text'),
  },
  'docs/react/dangerously-api-key.en-US': {
    frontmatter: fm26,
    toc: t26,
    demoIndex: dmi26,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/dangerously-api-key.en-US.md?type=text'),
  },
  'docs/react/dangerously-api-key.zh-CN': {
    frontmatter: fm27,
    toc: t27,
    demoIndex: dmi27,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/dangerously-api-key.zh-CN.md?type=text'),
  },
  'docs/spec/authentic-consistent.en-US': {
    frontmatter: fm28,
    toc: t28,
    demoIndex: dmi28,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/authentic-consistent.en-US.md?type=text'),
  },
  'docs/spec/authentic-consistent.zh-CN': {
    frontmatter: fm29,
    toc: t29,
    demoIndex: dmi29,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/authentic-consistent.zh-CN.md?type=text'),
  },
  'docs/spec/expression-user-send.en-US': {
    frontmatter: fm30,
    toc: t30,
    demoIndex: dmi30,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/expression-user-send.en-US.md?type=text'),
  },
  'docs/spec/expression-user-send.zh-CN': {
    frontmatter: fm31,
    toc: t31,
    demoIndex: dmi31,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/expression-user-send.zh-CN.md?type=text'),
  },
  'docs/spec/conversation-design.en-US': {
    frontmatter: fm32,
    toc: t32,
    demoIndex: dmi32,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/conversation-design.en-US.md?type=text'),
  },
  'docs/spec/conversation-design.zh-CN': {
    frontmatter: fm33,
    toc: t33,
    demoIndex: dmi33,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/conversation-design.zh-CN.md?type=text'),
  },
  'docs/spec/intention-introduce.en-US': {
    frontmatter: fm34,
    toc: t34,
    demoIndex: dmi34,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/intention-introduce.en-US.md?type=text'),
  },
  'docs/spec/intention-introduce.zh-CN': {
    frontmatter: fm35,
    toc: t35,
    demoIndex: dmi35,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/intention-introduce.zh-CN.md?type=text'),
  },
  'docs/playground/independent.en-US': {
    frontmatter: fm36,
    toc: t36,
    demoIndex: dmi36,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/playground/independent.en-US.md?type=text'),
  },
  'docs/playground/independent.zh-CN': {
    frontmatter: fm37,
    toc: t37,
    demoIndex: dmi37,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/playground/independent.zh-CN.md?type=text'),
  },
  'docs/react/model-use-openai.en-US': {
    frontmatter: fm38,
    toc: t38,
    demoIndex: dmi38,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/model-use-openai.en-US.md?type=text'),
  },
  'docs/react/model-use-openai.zh-CN': {
    frontmatter: fm39,
    toc: t39,
    demoIndex: dmi39,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/model-use-openai.zh-CN.md?type=text'),
  },
  'docs/react/use-with-rsbuild.en-US': {
    frontmatter: fm40,
    toc: t40,
    demoIndex: dmi40,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=text'),
  },
  'docs/react/use-with-rsbuild.zh-CN': {
    frontmatter: fm41,
    toc: t41,
    demoIndex: dmi41,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=text'),
  },
  'docs/react/model-use-other.en-US': {
    frontmatter: fm42,
    toc: t42,
    demoIndex: dmi42,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/model-use-other.en-US.md?type=text'),
  },
  'docs/react/model-use-other.zh-CN': {
    frontmatter: fm43,
    toc: t43,
    demoIndex: dmi43,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/model-use-other.zh-CN.md?type=text'),
  },
  'docs/spec/hybrid-ui-design.en-US': {
    frontmatter: fm44,
    toc: t44,
    demoIndex: dmi44,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/hybrid-ui-design.en-US.md?type=text'),
  },
  'docs/spec/hybrid-ui-design.zh-CN': {
    frontmatter: fm45,
    toc: t45,
    demoIndex: dmi45,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/hybrid-ui-design.zh-CN.md?type=text'),
  },
  'docs/react/model-use-qwen.en-US': {
    frontmatter: fm46,
    toc: t46,
    demoIndex: dmi46,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/model-use-qwen.en-US.md?type=text'),
  },
  'docs/react/model-use-qwen.zh-CN': {
    frontmatter: fm47,
    toc: t47,
    demoIndex: dmi47,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/model-use-qwen.zh-CN.md?type=text'),
  },
  'docs/spec/wake-up-ai-icon.en-US': {
    frontmatter: fm48,
    toc: t48,
    demoIndex: dmi48,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/wake-up-ai-icon.en-US.md?type=text'),
  },
  'docs/spec/wake-up-ai-icon.zh-CN': {
    frontmatter: fm49,
    toc: t49,
    demoIndex: dmi49,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/wake-up-ai-icon.zh-CN.md?type=text'),
  },
  'docs/react/use-with-next.en-US': {
    frontmatter: fm50,
    toc: t50,
    demoIndex: dmi50,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=text'),
  },
  'docs/react/use-with-next.zh-CN': {
    frontmatter: fm51,
    toc: t51,
    demoIndex: dmi51,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=text'),
  },
  'docs/react/use-with-vite.en-US': {
    frontmatter: fm52,
    toc: t52,
    demoIndex: dmi52,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=text'),
  },
  'docs/react/use-with-vite.zh-CN': {
    frontmatter: fm53,
    toc: t53,
    demoIndex: dmi53,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=text'),
  },
  'docs/spec/smooth-natural.en-US': {
    frontmatter: fm54,
    toc: t54,
    demoIndex: dmi54,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/smooth-natural.en-US.md?type=text'),
  },
  'docs/spec/smooth-natural.zh-CN': {
    frontmatter: fm55,
    toc: t55,
    demoIndex: dmi55,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/smooth-natural.zh-CN.md?type=text'),
  },
  'docs/react/contributing.en-US': {
    frontmatter: fm56,
    toc: t56,
    demoIndex: dmi56,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/contributing.en-US.md?type=text'),
  },
  'docs/react/contributing.zh-CN': {
    frontmatter: fm57,
    toc: t57,
    demoIndex: dmi57,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=text'),
  },
  'docs/react/use-with-umi.en-US': {
    frontmatter: fm58,
    toc: t58,
    demoIndex: dmi58,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=text'),
  },
  'docs/react/use-with-umi.zh-CN': {
    frontmatter: fm59,
    toc: t59,
    demoIndex: dmi59,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=text'),
  },
  'docs/spec/human-touch.en-US': {
    frontmatter: fm60,
    toc: t60,
    demoIndex: dmi60,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/human-touch.en-US.md?type=text'),
  },
  'docs/spec/human-touch.zh-CN': {
    frontmatter: fm61,
    toc: t61,
    demoIndex: dmi61,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/human-touch.zh-CN.md?type=text'),
  },
  'docs/spec/role-design.en-US': {
    frontmatter: fm62,
    toc: t62,
    demoIndex: dmi62,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/role-design.en-US.md?type=text'),
  },
  'docs/spec/role-design.zh-CN': {
    frontmatter: fm63,
    toc: t63,
    demoIndex: dmi63,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/role-design.zh-CN.md?type=text'),
  },
  'docs/react/introduce.en-US': {
    frontmatter: fm64,
    toc: t64,
    demoIndex: dmi64,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/introduce.en-US.md?type=text'),
  },
  'docs/react/introduce.zh-CN': {
    frontmatter: fm65,
    toc: t65,
    demoIndex: dmi65,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=text'),
  },
  'docs/spec/follow-up.en-US': {
    frontmatter: fm66,
    toc: t66,
    demoIndex: dmi66,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/follow-up.en-US.md?type=text'),
  },
  'docs/spec/follow-up.zh-CN': {
    frontmatter: fm67,
    toc: t67,
    demoIndex: dmi67,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/follow-up.zh-CN.md?type=text'),
  },
  'docs/spec/introduce.en-US': {
    frontmatter: fm68,
    toc: t68,
    demoIndex: dmi68,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/introduce.en-US.md?type=text'),
  },
  'docs/spec/introduce.zh-CN': {
    frontmatter: fm69,
    toc: t69,
    demoIndex: dmi69,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/introduce.zh-CN.md?type=text'),
  },
  'docs/spec/confirm.en-US': {
    frontmatter: fm70,
    toc: t70,
    demoIndex: dmi70,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/confirm.en-US.md?type=text'),
  },
  'docs/spec/confirm.zh-CN': {
    frontmatter: fm71,
    toc: t71,
    demoIndex: dmi71,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/confirm.zh-CN.md?type=text'),
  },
  'docs/spec/error.en-US': {
    frontmatter: fm72,
    toc: t72,
    demoIndex: dmi72,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/error.en-US.md?type=text'),
  },
  'docs/spec/error.zh-CN': {
    frontmatter: fm73,
    toc: t73,
    demoIndex: dmi73,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/error.zh-CN.md?type=text'),
  },
  'docs/spec/start.en-US': {
    frontmatter: fm74,
    toc: t74,
    demoIndex: dmi74,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/start.en-US.md?type=text'),
  },
  'docs/spec/start.zh-CN': {
    frontmatter: fm75,
    toc: t75,
    demoIndex: dmi75,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/start.zh-CN.md?type=text'),
  },
  'docs/react/faq.en-US': {
    frontmatter: fm76,
    toc: t76,
    demoIndex: dmi76,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/faq.en-US.md?type=text'),
  },
  'docs/react/faq.zh-CN': {
    frontmatter: fm77,
    toc: t77,
    demoIndex: dmi77,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=text'),
  },
  'docs/spec/hint.en-US': {
    frontmatter: fm78,
    toc: t78,
    demoIndex: dmi78,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/hint.en-US.md?type=text'),
  },
  'docs/spec/hint.zh-CN': {
    frontmatter: fm79,
    toc: t79,
    demoIndex: dmi79,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/hint.zh-CN.md?type=text'),
  },
  'docs/spec/end.en-US': {
    frontmatter: fm80,
    toc: t80,
    demoIndex: dmi80,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/end.en-US.md?type=text'),
  },
  'docs/spec/end.zh-CN': {
    frontmatter: fm81,
    toc: t81,
    demoIndex: dmi81,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/end.zh-CN.md?type=text'),
  },
  'components/attachments/index.en-US': {
    frontmatter: fm82,
    toc: t82,
    demoIndex: dmi82,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/attachments/index.en-US.md?type=text'),
  },
  'components/attachments/index.zh-CN': {
    frontmatter: fm83,
    toc: t83,
    demoIndex: dmi83,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/attachments/index.zh-CN.md?type=text'),
  },
  'components/bubble/index.en-US': {
    frontmatter: fm84,
    toc: t84,
    demoIndex: dmi84,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/bubble/index.en-US.md?type=text'),
  },
  'components/bubble/index.zh-CN': {
    frontmatter: fm85,
    toc: t85,
    demoIndex: dmi85,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/bubble/index.zh-CN.md?type=text'),
  },
  'components/conversations/index.en-US': {
    frontmatter: fm86,
    toc: t86,
    demoIndex: dmi86,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/conversations/index.en-US.md?type=text'),
  },
  'components/conversations/index.zh-CN': {
    frontmatter: fm87,
    toc: t87,
    demoIndex: dmi87,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/conversations/index.zh-CN.md?type=text'),
  },
  'components/overview/index.en-US': {
    frontmatter: fm88,
    toc: t88,
    demoIndex: dmi88,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/overview/index.en-US.md?type=text'),
  },
  'components/overview/index.zh-CN': {
    frontmatter: fm89,
    toc: t89,
    demoIndex: dmi89,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/overview/index.zh-CN.md?type=text'),
  },
  'components/prompts/index.en-US': {
    frontmatter: fm90,
    toc: t90,
    demoIndex: dmi90,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/prompts/index.en-US.md?type=text'),
  },
  'components/prompts/index.zh-CN': {
    frontmatter: fm91,
    toc: t91,
    demoIndex: dmi91,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/prompts/index.zh-CN.md?type=text'),
  },
  'components/sender/index.en-US': {
    frontmatter: fm92,
    toc: t92,
    demoIndex: dmi92,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/sender/index.en-US.md?type=text'),
  },
  'components/sender/index.zh-CN': {
    frontmatter: fm93,
    toc: t93,
    demoIndex: dmi93,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/sender/index.zh-CN.md?type=text'),
  },
  'components/suggestion/index.en-US': {
    frontmatter: fm94,
    toc: t94,
    demoIndex: dmi94,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/suggestion/index.en-US.md?type=text'),
  },
  'components/suggestion/index.zh-CN': {
    frontmatter: fm95,
    toc: t95,
    demoIndex: dmi95,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/suggestion/index.zh-CN.md?type=text'),
  },
  'components/thought-chain/index.en-US': {
    frontmatter: fm96,
    toc: t96,
    demoIndex: dmi96,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/thought-chain/index.en-US.md?type=text'),
  },
  'components/thought-chain/index.zh-CN': {
    frontmatter: fm97,
    toc: t97,
    demoIndex: dmi97,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/thought-chain/index.zh-CN.md?type=text'),
  },
  'components/useXAgent/index.en-US': {
    frontmatter: fm98,
    toc: t98,
    demoIndex: dmi98,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/useXAgent/index.en-US.md?type=text'),
  },
  'components/useXAgent/index.zh-CN': {
    frontmatter: fm99,
    toc: t99,
    demoIndex: dmi99,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/useXAgent/index.zh-CN.md?type=text'),
  },
  'components/useXChat/index.en-US': {
    frontmatter: fm100,
    toc: t100,
    demoIndex: dmi100,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/useXChat/index.en-US.md?type=text'),
  },
  'components/useXChat/index.zh-CN': {
    frontmatter: fm101,
    toc: t101,
    demoIndex: dmi101,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/useXChat/index.zh-CN.md?type=text'),
  },
  'components/welcome/index.en-US': {
    frontmatter: fm102,
    toc: t102,
    demoIndex: dmi102,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/welcome/index.en-US.md?type=text'),
  },
  'components/welcome/index.zh-CN': {
    frontmatter: fm103,
    toc: t103,
    demoIndex: dmi103,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/welcome/index.zh-CN.md?type=text'),
  },
  'components/x-provider/index.en-US': {
    frontmatter: fm104,
    toc: t104,
    demoIndex: dmi104,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/x-provider/index.en-US.md?type=text'),
  },
  'components/x-provider/index.zh-CN': {
    frontmatter: fm105,
    toc: t105,
    demoIndex: dmi105,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/x-provider/index.zh-CN.md?type=text'),
  },
  'components/x-request/index.en-US': {
    frontmatter: fm106,
    toc: t106,
    demoIndex: dmi106,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/x-request/index.en-US.md?type=text'),
  },
  'components/x-request/index.zh-CN': {
    frontmatter: fm107,
    toc: t107,
    demoIndex: dmi107,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/x-request/index.zh-CN.md?type=text'),
  },
  'components/x-stream/index.en-US': {
    frontmatter: fm108,
    toc: t108,
    demoIndex: dmi108,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/x-stream/index.en-US.md?type=text'),
  },
  'components/x-stream/index.zh-CN': {
    frontmatter: fm109,
    toc: t109,
    demoIndex: dmi109,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/x-stream/index.zh-CN.md?type=text'),
  },
  'changelog-cn': {
    frontmatter: fm110,
    toc: t110,
    demoIndex: dmi110,
    textGetter: () => import(/* webpackChunkName: "meta__CHANGELOG.zh-CN.md__zh-CN" */'/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=text'),
  },
  'changelog': {
    frontmatter: fm111,
    toc: t111,
    demoIndex: dmi111,
    textGetter: () => import(/* webpackChunkName: "meta__CHANGELOG.en-US.md__en-US" */'/opt/buildhome/repo/CHANGELOG.en-US.md?type=text'),
  },
}

export { tabs as tabsMeta } from './tabs';
