/**
 * 调整尺寸变量基础参数
 */
export var ResizingConstraint;
/**
 * group 或 symbol 的 layout 参数类型
 */
(function (ResizingConstraint) {
  ResizingConstraint[ResizingConstraint["None"] = 63] = "None";
  ResizingConstraint[ResizingConstraint["Top"] = 31] = "Top";
  ResizingConstraint[ResizingConstraint["Right"] = 62] = "Right";
  ResizingConstraint[ResizingConstraint["Bottom"] = 55] = "Bottom";
  ResizingConstraint[ResizingConstraint["Left"] = 59] = "Left";
  ResizingConstraint[ResizingConstraint["Width"] = 61] = "Width";
  ResizingConstraint[ResizingConstraint["Height"] = 47] = "Height";
})(ResizingConstraint || (ResizingConstraint = {}));