/**
 * 对 symbol 进行自定义处理的方法
 */

export var LayerSelectorMatchMethod;

/**
 * 选择图层的方法
 */
(function (LayerSelectorMatchMethod) {
  LayerSelectorMatchMethod[LayerSelectorMatchMethod["Equal"] = 0] = "Equal";
  LayerSelectorMatchMethod[LayerSelectorMatchMethod["Included"] = 1] = "Included";
})(LayerSelectorMatchMethod || (LayerSelectorMatchMethod = {}));