'use strict';

var React = require('react');
var JsonML = require('jsonml.js/lib/utils');
var utils = require('./utils');

var cid = 0;
module.exports = function toReactComponent(jsonml) {
  var converters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  var defaultConverters = [[function (node) {
    return JsonML.getTagName(node) === 'style';
  }, function (node, index) {
    var tagName = JsonML.getTagName(node);
    var attrs = JsonML.getAttributes(node);
    var styles = JsonML.getChildren(node)[0];
    return React.createElement(tagName, utils.assign({
      key: index,
      dangerouslySetInnerHTML: {
        __html: styles
      }
    }, attrs));
  }], [function (node) {
    return typeof node === 'string';
  }, function (node) {
    return node;
  }], [function () {
    return true;
  }, function (node, index) {
    var attrs = utils.assign({ key: index }, JsonML.getAttributes(node));
    if (attrs.class) {
      attrs.className = attrs.class;
      delete attrs.class;
    }
    if (attrs.style) {
      attrs.style = utils.toStyleObject(attrs.style);
    }
    if (attrs.src) {
      attrs.src = utils.sanitizeUrl(attrs.src);
    }
    if (attrs.href) {
      attrs.href = utils.sanitizeUrl(attrs.href);
    }

    var tagName = JsonML.getTagName(node);
    return React.createElement(tagName, utils.reactifyAttrs(attrs), utils.isStandalone(tagName) ? undefined : JsonML.getChildren(node).map(innerToReactComponent));
  }]];

  var mergeConverters = converters.concat(defaultConverters);

  function innerToReactComponent(jsonml, index) {
    return utils.cond(jsonml, mergeConverters, index);
  }

  return utils.cond(jsonml, mergeConverters, cid++);
};